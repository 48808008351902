var processInclude = require('base/util');
// This list should include any JS files that check for specific nodes in DOM that may be added to belowFold region
var coreFiles = {
    productTile: require('core/components/productTile'),
    video: require('core/components/video'),
    slider: require('core/components/slider').init,
    pageDesigner: require('core/pageDesigner')
}

$(document).ready(() => {

    // Render any components in the Below Fold region via ajax after page load
    if ($('#belowFoldRegion').length) {
        const $belowFoldRegion = $('#belowFoldRegion');
        const url = $belowFoldRegion.data('url');
        const populateRegion = new Promise(resolve => {
            // Send to Page-Show, return HTML for ajaxRegion template
            $.ajax({
                url: url,
                method: 'GET',
                success: response => {
                    $belowFoldRegion.html(response);
                    resolve();
                },
                error: err => {
                    console.error('Unable to render Below Fold region ', err);
                }
            });
        });

        populateRegion.then(() => {
            Object.keys(coreFiles).forEach(function (key) {
                processInclude(coreFiles[key]);
            });
        });
    }

});